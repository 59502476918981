import { ArrowRight, MagnifyingGlass } from './Icons';
import { brandColors } from '@theme';
import { ExternalLink } from './ExternalLink';
import { Flex, Box, useBreakpointValue, UnorderedList, ListItem, Link as Link_1, GridItem } from '@chakra-ui/react';
import { Grid8 } from '@components/Grid';
import { Link, HStack, Text } from '@chakra-ui/react';
import { MotionBox } from './MotionBox';
import { padding } from '@theme';
import { SiteMetadata } from '@lib/api';
import { useSiteContext } from '@hooks';
import * as React from 'react';

const brandColorsArray = Object.values(brandColors);

export const NavigationLink: React.FC<{ to: string; title: string; activeLink: string }> = (props) => {
	const isActive = props.activeLink.includes(props.to);

	return (
		<Flex
			title={props.title}
			alignItems="center"
			position="relative"
			left="-1.72em"
			transition="left 0.3s ease-in-out 0.1s"
			_hover={{
				left: '0',
				transition: 'left 0.3s ease-in-out',
			}}
		>
			<Link href={props.to}>
				<ArrowRight mr="0.5em" />{' '}
				<Box as="span" borderBottom={isActive && 'solid 2px black'}>
					{props.children}
				</Box>
			</Link>
		</Flex>
	);
};

export const Navigation: React.FC<{ siteMeta: SiteMetadata }> = (props) => {
	const siteContext = useSiteContext();

	const [background, setBackround] = React.useState<string>(brandColorsArray[0]);

	const visibleVariant = React.useMemo(() => {
		if (!siteContext.isHamburgering) {
			return 'hidden';
		}

		return 'visible';
	}, [siteContext.isHamburgering]);

	React.useEffect(() => {
		setBackround(brandColorsArray[Math.floor(Math.random() * brandColorsArray.length)]);
	}, []);

	return (
		<MotionBox
			animate={visibleVariant}
			backgroundColor={background ?? 'brand.gray'}
			className="navigation-expanded"
			exit="exit"
			height="100%"
			initial="initial"
			left={0}
			minHeight="100%"
			overflow={'hidden'}
			pointerEvents="initial"
			position="fixed"
			top={0}
			width="100vw"
			zIndex="1300"
			variants={{
				initial: {
					opacity: 0,
					transition: {
						ease: 'easeIn',
						duration: 1,
					},
				},
				hidden: {
					opacity: 0,
					y: '100%',
					transition: {
						ease: 'easeIn',
						duration: 0.2,
					},
				},
				visible: {
					opacity: 1,
					y: '0%',
					transition: {
						ease: 'easeOut',
						duration: 0.4,
					},
				},
				exit: {
					opacity: 0,
					transition: { duration: 0.4, ease: [0.91, 0.01, 0.67, 1] },
				},
			}}
		>
			<Grid8 width={'100%'}>
				<GridItem
					fontSize={{ base: '1.2em', sm: '1.99em', md: '2.6em', lg: '3.5vw' }}
					gridColumn={{ base: '1 / -1', sm: '5 / span 4' }}
					lineHeight={{ base: '1.2rem', sm: '1.1rem' }}
					marginTop={{ base: '7.5rem', md: '6.5rem', lg: '2.5rem' }}
					pointerEvents="initial"
					textTransform="uppercase"
				>
					<UnorderedList role="navigation" styleType="none" ml={0} spacing={3}>
						<ListItem display={{ base: 'initial', sm: 'none' }}>
							<Link_1>
								<MagnifyingGlass onClick={siteContext.openSearch} />
							</Link_1>
						</ListItem>
						<ListItem overflow="hidden">
							<NavigationLink to="/work" title="Work" activeLink={siteContext.currentPath}>
								Work
							</NavigationLink>
						</ListItem>
						{/* <ListItem overflow="hidden">
							<NavigationLink to="/originals" title="Originals" activeLink={siteContext.currentPath}>
								Originals
							</NavigationLink>
						</ListItem> */}
						<ListItem overflow="hidden">
							<NavigationLink to="/about" title="About" activeLink={siteContext.currentPath}>
								About
							</NavigationLink>
						</ListItem>
						<ListItem overflow="hidden">
							<NavigationLink to="/contact" title="Contact" activeLink={siteContext.currentPath}>
								Contact
							</NavigationLink>
						</ListItem>
						<ListItem overflow="hidden">
							<NavigationLink to="/careers" title="Careers" activeLink={siteContext.currentPath}>
								Careers
							</NavigationLink>
						</ListItem>
						{/* <ListItem overflow="hidden">
							<NavigationLink to="/research" title="Research" activeLink={siteContext.currentPath}>
								Research
							</NavigationLink>
						</ListItem> */}
						{/* <ListItem overflow="hidden">
							<NavigationLink to="/sandbox" title="Sandbox" activeLink={siteContext.currentPath}>
								Sandbox
							</NavigationLink>
						</ListItem> */}
						<ListItem overflow="hidden">
							<NavigationLink to="https://www.buckgames.co" title="Games" activeLink={siteContext.currentPath}>
								Games
							</NavigationLink>
						</ListItem>
						<ListItem overflow="hidden">
							<NavigationLink to="/goods" title="Goods" activeLink={siteContext.currentPath}>
								Goods
							</NavigationLink>
						</ListItem>
					</UnorderedList>
				</GridItem>
			</Grid8>

			<Grid8 position={'absolute'} bottom={0} paddingBottom={padding} width={'100%'}>
				<GridItem gridColumn={{ base: '1/-1', sm: '1/span 4' }} alignSelf={'flex-end'} order={{ base: 1, sm: 0 }}>
					© {props.siteMeta.site.copyrightYear} {props.siteMeta.site.title}
				</GridItem>
				<GridItem gridColumn={{ base: '1/-1', sm: '5/span 4', lg: '5/span 3' }}>
					<Box display={'flex'} flexDirection={{ base: 'column', md: 'row' }} ml={0} justifyContent={{ base: '', sm: 'space-between' }}>
						{props.siteMeta.site.socialNetworks.map((socialNetwork) => {
							return (
								<ExternalLink key={socialNetwork._key} href={socialNetwork.url}>
									{socialNetwork.name}
								</ExternalLink>
							);
						})}
					</Box>
				</GridItem>
			</Grid8>
		</MotionBox>
	);
};

export default Navigation;
